*{
  box-sizing: border-box;
}
body{
  color:#222;
}

main{
  max-width: 1200px;
  padding: 10px;
  margin: 0 auto;
}
header{
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}
header a{
  text-decoration: none;
  color: inherit;
}
header a.logo{
  font-weight: bold;
}
header nav{
  display: flex;
  gap: 15px;
  
}
div.post{
  display: grid;
  grid-template-columns: .9fr 1.1fr;
  gap: 20px;
  margin-bottom: 30px;
  }
div.post div.texts h2{
  margin: 0;
  font-size: 1.8rem;
}

div.post p.info{
  margin: 6px 0;
  color:#888;
  font-size:.9rem;
  font-weight: bold;
  display: flex;
  gap: 10px;
}
div.post p.info a.author{
  color: #333;

}
div.post p.summary{
  margin:10px 0;
  line-height: 1.4rem;
}
form.login, form.register{
  max-width: 400px;
  margin: 0 auto;

}
input{
  display: block;
  margin-bottom: 5px;
  width: 100%;
  padding: 5px 7px;
  border: 2px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
}
button{
  width: 100%;
  display: block;
  background-color: #555;
  border: 0;
  color: #fff;
  border-radius: 5px;
  padding: 7px 0;
}
form h1{
  text-align: center;
  margin-bottom: 20px;
}
form.register h1{
  text-align: center;
}